import { Button } from "@/components/ui/Button";
import { Input } from "@/components/ui/Input";
import { Label } from "@/components/ui/Label";
import { Switch } from "@/components/ui/Switch";
import { OpenAiFunction, UserPhone } from "@/dtos/userPhone";
import api from "@/services/api";
import getValidationErrors from "@/utils/getYupValidationErrors";
import getMessage, { getFunctions } from "@/utils/TestAssistent";
import { Image, Loader2, MessageCirclePlus, Mic } from "lucide-react";
import React, { useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { Location, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Sidebar from "./components/Sidebar";

interface NavigationProps extends Location {
  state: {
    phone: UserPhone;
    isConnected: boolean;
  };
}

interface FormProps {
  assistantId: string;
  openAIKey: string;
  phone: string;
  webhookUrl: string;
  openAiFunction?: OpenAiFunction[];
  bufferMessageTime: number;
  botDelay: number;
}

const Schema = Yup.object().shape({
  assistantId: Yup.string().required("Obrigatório"),
  openAIKey: Yup.string().required("Obrigatório"),
  phone: Yup.string()
    .required("Obrigatório")
    .test(
      "is-valid-phone",
      "Número inválido - preencha com o código do país (55) + DDD",
      (value) => {
        if (value.length < 12 || value.length > 13) {
          return false;
        }

        return true;
      }
    ),
  webhookUrl: Yup.string().url("URL inválida"),
  openAiFunction: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Obrigatório"),
      url: Yup.string().url("URL inválida").required("Obrigatório"),
    })
  ),
  bufferMessageTime: Yup.number()
    .required("Obrigatório"),
  botDelay: Yup.number().required("Obrigatório"),
});

const OPEN_AI_ERROR = "OPEN_AI_KEY_OR_ASSISTANT_ID_INVALID";

const Dashboard: React.FC = () => {
  const { state } = useLocation() as NavigationProps;
  const navigate = useNavigate();

  const currentPhone = state?.phone;

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [testing, setTesting] = useState(false);
  const [error, setError] = useState({} as FormProps);

  const [assistantId, setAssistantId] = useState(
    currentPhone?.openAiAssistantId || ""
  );
  const [openAIKey, setOpenAIKey] = useState(
    currentPhone?.openAiSecretKey || ""
  );
  const [audioMessageEnabled, setAudioMessageEnabled] = useState(
    currentPhone?.audioMessageEnabled
  );
  const [splitMessageEnabled, setSplitMessageEnabled] = useState(
    currentPhone?.splitMessageEnabled || false
  );
  const [bufferMessageTime, setBufferMessageTime] = useState(
    currentPhone?.bufferMessageTime !== undefined ? currentPhone?.bufferMessageTime : 15
  );
  const [botDelay, setBotDelay] = useState(
    currentPhone?.botDelay !== undefined ? currentPhone?.botDelay : 5
  );
  const [imageMessageEnabled, setImageMessageEnabled] = useState(
    currentPhone?.imageMessageEnabled
  );
  const [webhookUrl, setWebhookUrl] = useState(currentPhone?.webhookUrl || "");
  const [openAiFunction, setOpenAiFunction] = useState(
    currentPhone?.openAiFunction || []
  );
  const [isLoadFunctions, setIsLoadFunctions] = useState(false);
  const [phone, setPhone] = useState(currentPhone?.phone || "55");

  const testAssistantConfig = async (testingFlow = true) => {
    if (!openAIKey || !assistantId) {
      alert.error("Preencha todos os campos antes de testar seu assistente");
      return;
    }

    setTesting(testingFlow);
    try {
      await getMessage({
        apiKey: openAIKey,
        assistantId,
        userMessage: "Oi",
      });

      if (testingFlow) {
        alert.success("Assistente testado com sucesso");
      }
    } catch (error: any) {
      alert.error(error?.message);

      if (!testingFlow) {
        throw new Error(OPEN_AI_ERROR);
      }
    } finally {
      setTesting(false);
    }
  };

  const handleCreate = async () => {
    setLoading(true);
    setError({} as FormProps);

    const data = {
      assistantId,
      openAIKey,
      phone,
      webhookUrl,
      openAiFunction,
      bufferMessageTime,
      botDelay,
    };

    try {
      await Schema.validate(data, {
        abortEarly: false,
      });

      await testAssistantConfig(false);

      if (currentPhone) {
        await api.patch(`/user-phone/${currentPhone._id}`, {
          openAiSecretKey: openAIKey,
          openAiAssistantId: assistantId,
          phone,
          audioMessageEnabled,
          imageMessageEnabled,
          webhookUrl,
          openAiFunction,
          bufferMessageTime,
          botDelay,
          splitMessageEnabled,
        });
      } else {
        await api.post("/user-phone", {
          openAiSecretKey: openAIKey,
          openAiAssistantId: assistantId,
          phone,
          audioMessageEnabled,
          imageMessageEnabled,
          webhookUrl,
          openAiFunction,
          bufferMessageTime,
          botDelay,
          splitMessageEnabled,
        });
      }
      alert.success("Atendente criado com sucesso");
      navigate("/dashboard");
    } catch (error: any) {
      if (error.message === OPEN_AI_ERROR) {
        return;
      }

      if (error instanceof Yup.ValidationError) {
        const newError = getValidationErrors(error) as FormProps;
        setError(newError as FormProps);
      } else {
        alert.error(
          `Erro ao ${
            currentPhone ? "atualizar" : "criar"
          } assistente. Tente novamente mais tarde`
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const loadAssistantFunctions = async () => {
    setIsLoadFunctions(true);
    try {
      const functions = await getFunctions(openAIKey, assistantId);

      const newFunctions = functions.map((f) => {
        const currentFunction = openAiFunction.find(
          (openAiFunction) => openAiFunction.name === f.name
        );

        if (currentFunction) {
          return currentFunction;
        } else {
          return f;
        }
      });

      setOpenAiFunction(newFunctions);
    } catch (error) {
      alert.error("Erro ao carregar as funções do assistente");
    } finally {
      setIsLoadFunctions(false);
    }
  };

  const buttonTitle = useMemo(() => {
    if (currentPhone) {
      return "Atualizar assistente";
    } else {
      return "Criar atendente";
    }
  }, [currentPhone]);

  return (
    <Sidebar title={`${state?.phone ? "Editar" : "Criar"} atendente`}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="space-y-1">
          <Label>ID do assistente</Label>
          <a
            className="ml-2"
            href="https://platform.openai.com/assistants"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-xs text-gray">Criar assistente</span>
          </a>
          <Input
            placeholder="Digite o ID do assistente"
            onChange={(e) => setAssistantId(e.target.value)}
            value={assistantId}
            error={error.assistantId}
          />
        </div>
        <div className="space-y-1">
          <Label>Chave de API OpenAI</Label>
          <a
            className="ml-2"
            href="https://platform.openai.com/api-keys"
            target="_blank"
            rel="noreferrer"
          >
            <span className="text-xs text-gray">Criar chave de API</span>
          </a>
          <Input
            placeholder="Digite a sua chave de api da openAI"
            onChange={(e) => setOpenAIKey(e.target.value)}
            value={openAIKey}
            error={error.openAIKey}
          />
        </div>
        <div className="space-y-1">
          <Label>
            Whatsapp{state?.isConnected && <span className="ml-2 text-xs text-green">(Conectado)</span>}
          </Label>

          <Input
            placeholder="EX: 55 99 9999-9999"
            onChange={(e) => setPhone(e.target.value.replace(/\D/g, ""))}
            value={phone}
            disabled={state?.isConnected}
            maxLength={13}
            error={error.phone}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-1 gap-4 mt-8">
        <div className=" flex items-center space-x-4 rounded-md border border-border p-4">
          <Mic />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none">
              Controle de interpretação de áudio
            </p>
            <p className="text-sm text-muted-foreground">
              Essa opção utilizará a API da OpenAI para interpretar áudios e
              pode elevar o consumo de recursos.
            </p>
          </div>
          <Switch
            className="data-[state=checked]:bg-green"
            checked={audioMessageEnabled}
            onCheckedChange={() => setAudioMessageEnabled(!audioMessageEnabled)}
          />
        </div>

        <div className=" flex items-center space-x-4 rounded-md border border-border p-4">
          <Image />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none">
              Controle de interpretação de imagem
            </p>
            <p className="text-sm text-muted-foreground">
              Essa opção utilizará o upload de imagens para a API da OpenAI e
              pode elevar o consumo de recursos. <br />
              <span className="text-gray text-xs">
                Disponível apenas nos modelos 4
              </span>
            </p>
          </div>
          <Switch
            className="data-[state=checked]:bg-green"
            checked={imageMessageEnabled}
            onCheckedChange={() => setImageMessageEnabled(!imageMessageEnabled)}
          />
        </div>

        <div className=" flex items-center space-x-4 rounded-md border border-border p-4">
          <MessageCirclePlus />
          <div className="flex-1 space-y-1">
            <p className="text-sm font-medium leading-none">
              Controle de segmentação de mensagens
            </p>
            <p className="text-sm text-muted-foreground">
              Essa opção permite que o atendente envie mensagens divididas em
              partes menores, ideal para envio de mensagens longas.
            </p>
          </div>
          <Switch
            className="data-[state=checked]:bg-green"
            checked={splitMessageEnabled}
            onCheckedChange={() => setSplitMessageEnabled(!splitMessageEnabled)}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8">
        <div className="space-y-1">
          <Label>Tempo do buffer de mensagens (segundos)</Label>
          <Input
            placeholder="Digite o buffer de mensagem"
            onChange={(e) =>
              setBufferMessageTime(Number(e.target.value.replace(/\D/g, "")))
            }
            value={bufferMessageTime}
            error={error?.bufferMessageTime?.toString()}
          />
        </div>

        <div className="space-y-1">
          <Label>Tempo de pausa na interação humana (minutos)</Label>
          <Input
            placeholder="Digite o tempo de pausa"
            onChange={(e) =>
              setBotDelay(Number(e.target.value.replace(/\D/g, "")))
            }
            value={botDelay}
            error={error?.botDelay?.toString()}
          />
        </div>
      </div>

      <div className="mb-8 mt-8 flex flex-col border border-border p-4 rounded-md">
        <div className="flex flex-col md:flex-row align-center justify-between">
          <div className="font-bold flex flex-1 flex-col mr-4 mb-2 md:mb-0">
            <span>Funções do assistente:</span>
            <p className="text-xs font-normal">
              Carregue as funções do assistente para adicionar ao bot do zatten
              e permitir o cadastro das URLs
            </p>
          </div>

          <Button
            variant="outline"
            size="lg"
            onClick={loadAssistantFunctions}
            disabled={isLoadFunctions}
          >
            {openAiFunction.length ? "Atualizar funções" : "Carregar funções"}
          </Button>
        </div>

        {openAiFunction.map((f, i) => (
          <div className="mt-4 flex align-center gap-2">
            <div className="flex-1">
              <Label className="text-xs text-gray font-normal">
                Nome da função
              </Label>
              <Input
                onChange={() => {}}
                disabled
                value={f.name}
                error={error.openAiFunction?.[i]?.name}
              />
            </div>

            <div className="flex-1">
              <Label className="text-xs font-normal">
                URL da função - POST
              </Label>
              <Input
                onChange={(e) => {
                  openAiFunction[i] = {
                    ...openAiFunction[i],
                    url: e.target.value,
                  };

                  setOpenAiFunction([...openAiFunction]);
                }}
                value={f.url}
                error={
                  error[
                    `openAiFunction[${i}].url` as unknown as keyof FormProps
                  ] as string
                }
              />
            </div>
          </div>
        ))}
      </div>

      <div className="space-y-1">
        <Label>Webhook - POST</Label>
        <a
          className="ml-2"
          href="https://phase-roquefort-ad1.notion.site/Documenta-o-de-webhooks-f1e7483890fe4e22b039a2123ba8a117"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-xs text-gray">Acessar documentação</span>
        </a>
        <Input
          name="Webhook - POST"
          onChange={(e) => setWebhookUrl(e.target.value)}
          value={webhookUrl}
          error={error.webhookUrl}
          placeholder="URL do seu webhook (opcional)"
        />
      </div>

      <div className="flex flex-row gap-4 mt-8">
        <Button
          size="lg"
          variant="outline"
          name="Voltar"
          disabled={loading || testing}
          onClick={() => navigate("/dashboard")}
        >
          Voltar
        </Button>
        <Button
          size="lg"
          className="flex-1"
          name={buttonTitle}
          disabled={loading || testing}
          onClick={handleCreate}
        >
          {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
          {buttonTitle}
        </Button>
      </div>
    </Sidebar>
  );
};

export default Dashboard;
